import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import { i18n as I18n, withTranslation } from '../utils/i18n';

import Page from '../components/page';

const FourOhFourPage = () => {
  return (
    <Page
      title={I18n.t('headers.404.title')}
      description={I18n.t('headers.404.description')}
    >
      <main className="404-page error-page">
        <div className="error-page__image">
          <ReactSVG src="/images/lost.svg" />
        </div>

        <div className="error-page__content">
          <h1>{I18n.t('error_pages.404.title')}</h1>
          <p>{I18n.t('error_pages.404.help_text')}</p>
          <ul className="error-page__links">
            <li>
              <Link href={I18n.t('navigation.search.url')}>
                <a>{I18n.t('error_pages.404.links.search')}</a>
              </Link>
            </li>
            <li>
              <Link href={I18n.t('navigation.bookings.url')}>
                <a>{I18n.t('error_pages.404.links.my_bookings')}</a>
              </Link>
            </li>
            <li>
              <Link href={I18n.t('navigation.sign_in.url')}>
                <a>{I18n.t('error_pages.404.links.sign_in')}</a>
              </Link>
            </li>
            <li>
              <Link href={I18n.t('navigation.faq.url')}>
                <a>{I18n.t('error_pages.404.links.help')}</a>
              </Link>
            </li>
            <li>
              <Link href={I18n.t('navigation.accounts.url')}>
                <a>{I18n.t('error_pages.404.links.my_account')}</a>
              </Link>
            </li>
            <li>
              <Link href={I18n.t('navigation.register.url')}>
                <a>{I18n.t('error_pages.404.links.register')}</a>
              </Link>
            </li>
          </ul>
          <p>{I18n.t('error_pages.404.contact')}</p>
          <p>
            <Link href={`mailto:${I18n.t('error_pages.404.contact_email')}`}>
              <a>
                <ReactSVG src="/images/icons/homepage/footer/email.svg" />
                {I18n.t('error_pages.404.contact_email')}
              </a>
            </Link>
          </p>
        </div>
      </main>
    </Page>
  );
};

export default withTranslation('common')(FourOhFourPage);
